import { AppBar, Toolbar, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { LoaderModal, PageWrapper } from '../../../components';
import { useField } from 'react-final-form';
import { useGetCurrentAuctionsQuery } from '../../../services/api/auctions-list';
import { useGetFormControlButtons } from '../hooks/useGetFormControlButtons';

const TypographyBreadcrumbs = styled(Typography)`
  font-size: 16px;
  flex-grow: 1;
`;

const StyledAppBar = styled(AppBar)`
  background: #fff;
  top: 72px;
`;

const ButtonWrapper = styled.div`
  > button {
    margin-left: 12px;
    height: max-content;
    span > span {
      @media only screen and (max-width: 600px) {
        display: none;
      }
    }
    .material-icons {
      @media only screen and (min-width: 600px) {
        display: none;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const Header = () => {
  const {
    input: { value: auctionType }
  } = useField('auctionType', { subscription: { value: true } });

  const { data } = useGetCurrentAuctionsQuery();

  const { CancelButton, SaveAsDraftButton, SubmitButton } = useGetFormControlButtons();

  if (!data) return null;

  const { autoaxess: currentAutoaxessAuction, frontline: currentFlAuction } = data;

  const currentAuction = auctionType === 'autoaxess' ? currentAutoaxessAuction : currentFlAuction;

  return (
    <StyledAppBar position="fixed" color="default">
      <PageWrapper className="">
        <Toolbar disableGutters>
          <TypographyBreadcrumbs variant="title">Upload Vehicle</TypographyBreadcrumbs>
          <LoaderModal modalId="loaderModal" loaderText="Loading" />
          <ButtonContainer>
            <CancelButton renderMobileIcon />
            <SaveAsDraftButton renderMobileIcon />
            <SubmitButton renderMobileIcon />
          </ButtonContainer>
          {/* <ButtonWrapper>
            <Button variant="contained" color="secondary" onClick={handleCancel}>
              <i className="material-icons">close</i>
              <span>Cancel</span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!currentAuction || editingUser}
            >
              <i className="material-icons">send</i>
              <span>Submit</span>
            </Button>
          </ButtonWrapper> */}
        </Toolbar>
      </PageWrapper>
    </StyledAppBar>
  );
};
