import { Icon, TextField } from '@material-ui/core';
import { Field } from 'react-final-form';
import { maxLength } from '../../../../utils/validators';
import { useState } from 'react';
import styled from 'styled-components';
import { SendIcon } from '../../icons/SendIcon';

const maxLength200 = maxLength(200);

const Wrapper = styled.div<{ isCollapsed?: boolean }>`
  border: 1px solid rgba(217, 217, 217, 1);
  background: rgba(217, 217, 217, 0.2);
  position: relative;
  border-radius: 8px;
  padding: ${props => (props.isCollapsed ? '16px 8px' : '16px 8px 8px 8px')};
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  color: rgba(102, 102, 102, 1);
`;

const InputWrapper = styled.div<{ grow?: boolean }>`
  background: rgba(34, 34, 34, 0.04);
  border-radius: 8px;
  flex-grow: ${props => (props.grow ? '1' : '0')};
  padding: 8px 8px 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NotesWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledTextField = styled(TextField)`
  & * input {
    font-size: 22px;
    line-height: 16px;
    padding: 0;
    width: 100%;
  }

  & * {
    padding: 0;
  }
`;

export const InspectorComments = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleCollapse = () => setIsCollapsed(c => !c);

  if (isCollapsed) {
    return (
      <Wrapper isCollapsed>
        <HeaderWrapper>
          <Title>Inspector Comments</Title>
          <Icon onClick={toggleCollapse}>{isCollapsed ? 'expand_more' : 'expand_less'}</Icon>
        </HeaderWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <HeaderWrapper>
        <Title>Inspector Comments</Title>
        <Icon onClick={toggleCollapse}>{isCollapsed ? 'expand_more' : 'expand_less'}</Icon>
      </HeaderWrapper>

      <NotesWrapper>
        <InputWrapper grow>
          <Field
            className="text-field"
            name="dealer_comments"
            multiline
            placeholder="Add notes..."
            component={StyledTextField}
            type="text"
            InputProps={{ disableUnderline: true }}
            validate={maxLength200}
            fullWidth
          />
        </InputWrapper>
        <InputWrapper>
          <SendIcon />
        </InputWrapper>
      </NotesWrapper>
    </Wrapper>
  );
};
