import styled from 'styled-components';
import { ImageConfig } from 'services/api/vehicle-sections/types/common';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { ImagePlaceholderIcon } from '../../../icons/ImagePlaceholder';
import { DeleteImageButton } from '../DeleteImageButton';
import { FormImageField } from './FormImageField';
import { useGetSectionConfigById } from '../../../hooks/useGetSubSectionConfig';
import { SectionConfig } from '../../../../../services/api/vehicle-sections/types';

type Props = {
  imageConfig: ImageConfig;
  name: string;
};

const Wrapper = styled.div`
  margin-top: 12px;
  display: grid;
  column-gap: 8px;
  row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: repeat(2, 144px);

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 144px);
  }
`;

const Label = styled.span`
  margin-top: 8px;
  font-size: 14px;
  line-height: 16.41px;
  color: rgba(102, 102, 102, 1);
`;

const FormWrapper = styled.div`
display: flex
flex-direction: column;
gap: 12px
`;

export const FormImages = ({ name: sectionName }: Props) => {
  const [sectionId, subSectionId] = sectionName.split('.');
  const config = useGetSectionConfigById(sectionId as keyof SectionConfig);
  if (!config) return null;

  return (
    <FormWrapper>
      <Label>{config.subsections[subSectionId].label}</Label>
      <Wrapper>
        <FieldArray name={`${sectionName}.images`}>
          {({ fields }) =>
            fields.map((name, i) => {
              return <FormImageField key={name} fieldName={name} sectionName={sectionName} i={i} />;
            })
          }
        </FieldArray>
      </Wrapper>
    </FormWrapper>
  );
};
