import { Checkbox } from '@material-ui/core';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 32px;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
`;

const CheckboxLabel = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
`;

const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(350px, 1fr));
  grid-template-rows: auto;
  row-gap: 26px;
  column-gap: 8px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    row-gap: 8px;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

type Props = {
  name: string;
};

export const VehicleEquipment = ({ name }: Props) => {
  return (
    <Wrapper>
      <Title>Optional Equipment</Title>
      <CheckboxGrid>
        <FieldArray name={name}>
          {({ fields }) =>
            fields.map((fieldName, i) => {
              return <EquipmentCheckbox key={fieldName} fieldName={fieldName} />;
            })
          }
        </FieldArray>
      </CheckboxGrid>
    </Wrapper>
  );
};

const EquipmentCheckbox = ({ fieldName }: { fieldName: string }) => {
  return (
    <CheckboxWrapper>
      <Field name={`${fieldName}.is_typical`}>
        {props => (
          <Checkbox
            color="primary"
            style={{ width: '12px', height: '12px' }}
            checked={props.input.value}
            onChange={e => props.input.onChange(e.target.checked)}
          />
        )}
      </Field>
      <Field name={`${fieldName}.option_name`}>{props => <CheckboxLabel>{props.input.value}</CheckboxLabel>}</Field>
    </CheckboxWrapper>
  );
};
