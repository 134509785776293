import { Field, useField } from 'react-final-form';
import { PageSection } from '../../../../components/VehicleCreation';
import { Checkbox, Collapse, FormControlLabel } from '@material-ui/core';
import { FormAutocompleteSelect } from '../../../../components';
import { useGetSellersQuery } from '../../../../services/api';
import { RegularSectionContainer } from '../../components/RegularSectionContainer';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { toggleIsSellerLocked } from '../../../../services/sellerLock/sellerLockSlice';
import { Grid } from '@material-ui/core';
import { FormTextField, FormFormattedField } from 'components/Form';
import { normalizeFormattedField } from 'utils';
import { validateMinBid, validateReservePrice } from 'utils/validators';
import { useUploadVehicleMutation } from '../../../../services/api/upload-vehicle';
import { useIsAutomaticUpload } from '../../hooks/useIsAutomaticUpload';
import { AutomaticVehicleInformationForm } from './AutomaticUploadForm';

const PatchedFormTextField = FormTextField as any;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: 60px;
  div:first-child {
    flex: 1 1 80%;
  }
`;

export const VehicleInformation = () => {
  const isAutomaticUpload = useIsAutomaticUpload();

  return (
    <RegularSectionContainer title="Vehicle Information">
      <AutomaticVehicleInformationForm />
    </RegularSectionContainer>
  );
};

const ConditionalSelectField = ({ name }: { name: string }) => {};
