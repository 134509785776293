import { Button, Dialog } from '@material-ui/core';
import { FormSpy, useForm } from 'react-final-form';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  handleProceed: () => void;
};

const StyledConfirmSubmitModal = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 520px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledHeader = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 4px;
  & > button {
    flex: 1;
  }
`;

const StyledListItem = styled.li`
  font-size: 18px;
`;

export const ConfirmSubmitDialog = ({ isOpen, closeModal, handleProceed }: Props) => {
  const form = useForm();

  const handleCancel = () => {
    closeModal();
    form.submit();
  };

  return (
    <FormSpy subscription={{ errors: true }}>
      {({ errors }) => {
        const labels = Object.values(errors || {}).flatMap(v => {
          if (typeof v === 'object') return Object.values(v);
          return v;
        });

        return (
          <Dialog open={isOpen} onClose={closeModal}>
            <StyledConfirmSubmitModal>
              <StyledHeader>
                Are you sure you want to submit the vehicle without following information filled:
              </StyledHeader>
              <ul>
                {labels
                  .filter(v => v !== 'Required')
                  .map(v => (
                    <StyledListItem key={v}>{v}</StyledListItem>
                  ))}
              </ul>
              <ButtonWrapper>
                <Button color="primary" onClick={handleCancel} variant="outlined">
                  Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={handleProceed}>
                  Proceed
                </Button>
              </ButtonWrapper>
            </StyledConfirmSubmitModal>
          </Dialog>
        );
      }}
    </FormSpy>
  );
};
