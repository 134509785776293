import { Field } from 'react-final-form';
import styled from 'styled-components';
import { ImagePlaceholderIcon } from '../../../icons/ImagePlaceholder';
import { DeleteImageButton } from '../DeleteImageButton';
import { useFieldArray } from 'react-final-form-arrays';
import { useDroppable } from '@dnd-kit/core';
import { replaceLocalImages, useLocalImages } from '../../../../../services/uploadVehicle/uploadVehicleSlice';
import { useDispatch } from 'react-redux';
import { useDeleteSectionImageMutation } from '../../../../../services/api/upload-vehicle';

const ImageWithLabel = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const ImageWrapper = styled.label<{ isOver: boolean }>`
  height: 108px;
  display: flex;
  align-items: center;
  background: rgba(217, 217, 217, 1);
  justify-content: center;
  opacity: ${props => (props.isOver ? '0.5' : '1')};
`;

const ImageContainer = styled.div`
  position: relative;
`;

const PlaceholderWrapper = styled.div`
  height: 108px;
  width: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Label = styled.span`
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: center;
  color: rgba(102, 102, 102, 1);
`;

type Props = {
  fieldName: string;
  sectionName: string;
  i: number;
};

export const FormImageField = ({ fieldName, sectionName, i }: Props) => {
  const { fields } = useFieldArray(`${sectionName}.images`);

  const { setNodeRef, isOver } = useDroppable({
    id: sectionName + '//' + i,
    disabled: !!fields.value?.[i]?.url
  });

  const [deleteImageMutation] = useDeleteSectionImageMutation();

  const localImages = useLocalImages(sectionName);

  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(
      replaceLocalImages({
        images: localImages.map(localImage => {
          if (fields.value[i].id === localImage.serverId) {
            return { ...localImage, isUploaded: false };
          }
          return localImage;
        }),
        section: sectionName
      })
    );
    deleteImageMutation(fields.value[i].id);
    if (fields.value[i].type === 'user_defined') {
      fields.remove(i);
    } else {
      fields.update(i, {
        ...fields.value[i],
        id: null,
        url: '',
        url_thumb: ''
      });
    }
  };

  return (
    <ImageWithLabel ref={setNodeRef}>
      <Field name={`${fieldName}.url`} type="file">
        {props => {
          return (
            <ImageContainer>
              <ImageWrapper isOver={isOver} htmlFor={`${fieldName}-upload-dialog`}>
                {props.input.value ? (
                  <StyledImage src={props.input.value} />
                ) : (
                  <PlaceholderWrapper>
                    <ImagePlaceholderIcon />
                  </PlaceholderWrapper>
                )}
                <input
                  id={`${fieldName}-upload-dialog`}
                  style={{ display: 'none' }}
                  type="file"
                  onChange={e => {
                    props.input.onChange(e.target?.files?.[0]);
                  }}
                />
              </ImageWrapper>
              {props.input.value && <DeleteImageButton onClick={handleDelete} />}
            </ImageContainer>
          );
        }}
      </Field>
      <Field name={`${fieldName}.label`}>{({ input }) => <Label>{input.value}</Label>}</Field>
    </ImageWithLabel>
  );
};
