import { Button, Dialog, Icon } from '@material-ui/core';
import { ImageConfig } from 'services/api/vehicle-sections/types/common';
import styled from 'styled-components';
import { FormImages } from './FormImages';
import { LocalImages } from './LocalImages';
import {
  LocalImage,
  closeSubsectionDialog,
  removeActiveDragImage,
  setActiveDragImage,
  useActiveDragImage,
  useIsImageDialogOpen,
  useLocalImages,
  setUploadedLocalImage
} from '../../../../services/uploadVehicle/uploadVehicleSlice';
import { useDispatch } from 'react-redux';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { DragItem } from './DragItem';
import { useForm } from 'react-final-form';
import { useSaveSectionImagesMutation, useUpsertSectionImageMutation } from '../../../../services/api/upload-vehicle';
import { useParams } from 'react-router-dom';
import { snackShow } from '../../../../actions';
import { createUpsertImagePayload } from '../../helpers';
import { LoaderModal } from '../../../../components';
import { useFieldArray } from 'react-final-form-arrays';

type Props = {
  imageConfig: ImageConfig;
  name: string;
};

const StyledDialog = styled(Dialog)`
  .upload-image-dialog {
    width: 645px;
    height: 720px;
    margin: 32px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(217, 217, 217, 1);
    background: rgba(255, 255, 255, 1);
  }
`;

const Wrapper = styled.div`
  border-radius: 8px;
  padding: 16px;
  display: flex;
  height: 100%;
  overflow-y: scroll;
  justify-content: space-between;
  flex-direction: column;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 21px;
  }
`;

const SaveButton = styled(Button)`
  margin-top: auto;
`;

export const UploadImageDialog = ({ imageConfig, name }: Props) => {
  const dispatch = useDispatch();
  const isOpen = useIsImageDialogOpen(name);
  const close = () => dispatch(closeSubsectionDialog(name));

  const { id: vehicleId } = useParams<{ id: string }>();
  const localImages = useLocalImages(name);

  const [saveImages, { isLoading }] = useSaveSectionImagesMutation();
  const [upsertImage] = useUpsertSectionImageMutation();

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 300,
      tolerance: 8
    }
  });
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 8
    }
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const activeDragImage = useActiveDragImage(name);

  const handleDragStart = (e: DragStartEvent) => {
    dispatch(setActiveDragImage({ section: name, image: e.active.data.current as any }));
  };

  const form = useForm();
  const { fields: fieldState } = useFieldArray(`${name}.images`);

  const handleSaveChange = async () => {
    const notUploaded = localImages.filter(img => !img.isUploaded);
    if (!fieldState || notUploaded.length === 0) {
      close();
      return;
    }

    const currentValue = fieldState.value || [];

    const [sectionName, subSectionName] = name.split('.');
    const files = notUploaded.map(img => img.file);

    const response = await saveImages({ files, sectionName, subSectionName, vehicleId });
    if ('data' in response) {
      const newUploads = response.data.data;
      form.change(`${name}.images`, [...currentValue, ...newUploads]);
    } else {
      dispatch(snackShow({ message: 'Uploading image failed', type: 'error' }));
    }

    close();
  };

  const handleDragEnd = async (e: DragEndEvent) => {
    const { active, over } = e;

    const item = active.data.current as LocalImage;

    dispatch(removeActiveDragImage(name));

    if (!item || !over) return;

    const [sectionName, index] = (over.id as string).split('//');
    if (!fieldState) return;

    const { value } = fieldState;

    const parsedI = Number(index);

    const copy = [...value];

    const payload = createUpsertImagePayload(vehicleId, name, copy[parsedI], item.file);

    const response = await upsertImage(payload);

    if ('data' in response) {
      copy[parsedI] = response.data.data;
      form.change(`${sectionName}.images`, copy);
      dispatch(setUploadedLocalImage({ section: name, imageId: item.id, serverId: response.data.data.id }));
    }
  };

  return (
    <StyledDialog
      classes={{ paperScrollPaper: 'upload-image-dialog' }}
      fullWidth
      maxWidth={'lg'}
      onClose={close}
      open={isOpen}
    >
      <LoaderModal forceOpen={isLoading} modalId="images-loading" loaderText="Saving images..." />
      <DndContext sensors={sensors} onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
        <Wrapper>
          <TopSection>
            <Header>
              <Title>Upload Photos</Title>
              <Icon onClick={close}>close</Icon>
            </Header>
            <LocalImages name={name} imageConfig={imageConfig} />
          </TopSection>
          <BottomSection>
            <FormImages name={name} imageConfig={imageConfig} />
            <SaveButton onClick={handleSaveChange} variant="contained" color="primary">
              Save
            </SaveButton>
          </BottomSection>
        </Wrapper>
        <DragOverlay>{activeDragImage && <DragItem item={activeDragImage} />}</DragOverlay>
      </DndContext>
    </StyledDialog>
  );
};
