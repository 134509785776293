import { Icon } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { SubSection } from 'services/api/vehicle-sections/types/common';
import styled from 'styled-components';
import { openNotesDialog, openSubsectionDialog } from '../../../../../services/uploadVehicle/uploadVehicleSlice';
import { AddImageIcon } from '../../../icons/AddImageIcon';
import { AdditionalSectionInfo } from '../AdditionalInfo';
import { ExpandIcon } from '../../../../../components/Icons/ExpandIcon';
import { CollapseIcon } from '../../../../../components/Icons/CollapseIcon';

type HeaderComponentProps = {
  subSectionConfig: SubSection;
  isCollapsed: boolean;
  toggleCollapse: () => void;
  name: string;
};

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  color: rgba(102, 102, 102, 1);

  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const RequiredLabel = styled.span`
  color: rgba(175, 41, 48, 1);
`;

const Controls = styled.div`
  display: flex;
  gap: 12px;
`;

const NotesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M7.85823 8.64844C8.06934 8.64844 8.25407 8.56927 8.4124 8.41094C8.57074 8.2526 8.6499 8.06788 8.6499 7.85676C8.6499 7.64566 8.57053 7.46095 8.41178 7.30261C8.25303 7.14428 8.0699 7.06511 7.8624 7.06511C7.64852 7.06511 7.46241 7.14449 7.30408 7.30324C7.14574 7.46197 7.06658 7.64509 7.06658 7.85259C7.06658 8.06649 7.14574 8.2526 7.30408 8.41094C7.46241 8.56927 7.64713 8.64844 7.85823 8.64844ZM7.85823 12.7859C8.06691 12.7859 8.25103 12.7082 8.41058 12.5526C8.57013 12.397 8.6499 12.2123 8.6499 11.9984C8.6499 11.7846 8.57053 11.5984 8.41178 11.4401C8.25303 11.2818 8.0699 11.2026 7.8624 11.2026C7.64852 11.2026 7.46241 11.2818 7.30408 11.4401C7.14574 11.5984 7.06658 11.7846 7.06658 11.9984C7.06658 12.2123 7.14634 12.397 7.30588 12.5526C7.46543 12.7082 7.64954 12.7859 7.85823 12.7859ZM7.85823 16.9318C8.06934 16.9318 8.25407 16.8526 8.4124 16.6943C8.57074 16.5359 8.6499 16.3512 8.6499 16.1401C8.6499 15.929 8.57053 15.7443 8.41178 15.5859C8.25303 15.4276 8.0699 15.3484 7.8624 15.3484C7.64852 15.3484 7.46241 15.4278 7.30408 15.5866C7.14574 15.7453 7.06658 15.9284 7.06658 16.1359C7.06658 16.3498 7.14574 16.5359 7.30408 16.6943C7.46241 16.8526 7.64713 16.9318 7.85823 16.9318ZM4.73323 20.8484C4.30614 20.8484 3.93549 20.6913 3.62125 20.3771C3.30702 20.0629 3.1499 19.6922 3.1499 19.2651V4.73176C3.1499 4.30468 3.30702 3.93402 3.62125 3.61979C3.93549 3.30555 4.30614 3.14844 4.73323 3.14844H15.3083C15.5251 3.14844 15.7317 3.1907 15.9282 3.27524C16.1247 3.35979 16.2958 3.47585 16.4416 3.62344L20.3749 7.55676C20.5225 7.7025 20.6386 7.87361 20.7231 8.07011C20.8076 8.26661 20.8499 8.47327 20.8499 8.69009V19.2651C20.8499 19.6922 20.6928 20.0629 20.3786 20.3771C20.0643 20.6913 19.6937 20.8484 19.2666 20.8484H4.73323ZM4.73323 19.2651H19.2666V8.88176H15.9124C15.6902 8.88176 15.502 8.80468 15.3478 8.65051C15.1937 8.49635 15.1166 8.30815 15.1166 8.08594V4.73176H4.73323V19.2651Z"
      fill="#757575"
    />
  </svg>
);

export const HeaderComponent = ({ subSectionConfig, toggleCollapse, isCollapsed, name }: HeaderComponentProps) => {
  const dispatch = useDispatch();
  const openImageUploadDialog = () => dispatch(openSubsectionDialog(name));
  const openNotes = () => dispatch(openNotesDialog(name));

  return (
    <HeaderWrapper>
      <Title>
        {subSectionConfig.label}
        {subSectionConfig.required && <RequiredLabel>*</RequiredLabel>}
      </Title>
      <Controls>
        {isCollapsed && (
          <>
            <Icon onClick={openNotes}>
              <NotesIcon />
            </Icon>
            <Icon onClick={openImageUploadDialog}>
              <AddImageIcon />
            </Icon>
          </>
        )}
        {subSectionConfig.additionalInfo && <AdditionalSectionInfo additionalInfo={subSectionConfig.additionalInfo} />}
        <Icon onClick={toggleCollapse}>{isCollapsed ? <ExpandIcon /> : <CollapseIcon />}</Icon>
      </Controls>
    </HeaderWrapper>
  );
};
