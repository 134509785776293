import { Field } from 'react-final-form';
import Tooltip from '../../../../components/Tooltip';
import { NotesIcon } from '../../icons/NotesIcon';
import { useDispatch } from 'react-redux';
import { openNotesDialog } from '../../../../services/uploadVehicle/uploadVehicleSlice';
import styled from 'styled-components';

type Props = {
  name: string;
};

const NotesTooltip = styled.span`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.63px;
  text-align: left;
`;

export const NotesField = ({ name }: Props) => {
  const dispatch = useDispatch();
  const openNotes = () => dispatch(openNotesDialog(name));

  const isTiresAndWheels = name.startsWith('tires_and_wheels');
  const displayField = isTiresAndWheels ? 'label' : 'notes';

  return (
    <Field name={`${name}.${displayField}`}>
      {props => {
        const hasValue = !!props.input.value;
        const hasTiresAndWheelsValue = hasValue && !props.input.value.includes('_/');
        const showTooltip = isTiresAndWheels ? hasTiresAndWheelsValue : hasValue;
        return (
          <Tooltip
            titleStyle={'uploadVehicle'}
            title={props.input.value || 'Add Note'}
            disableHoverListener={!showTooltip}
          >
            <div style={{ cursor: 'pointer' }} onClick={openNotes}>
              <NotesIcon isActive={showTooltip} />
            </div>
          </Tooltip>
        );
      }}
    </Field>
  );
};
