import apiSlice from '..';
import { SectionConfig } from './types';

export type SectionsOrder = Array<[keyof SectionConfig | 'dealer_comments', string[]]>;

export const defaultSectionOrder: SectionsOrder = [
  ['odb', ['odb_check_readiness']],
  ['title_issues', []],
  [
    'exterior',
    [
      'exterior_pictures',
      'vin_plate_pictures',
      'license_plate',
      'keys',
      'under_body_and_frame',
      'trunk_and_rear_frame',
      'catalytic_converter_present',
      'suspension_alternations',
      'other_glass_damage',
      'windshield',
      'b_and_c_frame_check'
    ]
  ],
  [
    'underhood_mechanical',
    [
      'oil_or_dipstick',
      'noticeable_oil_leak',
      'transmission_dipstick',
      'coolant',
      'head_gasket_condition',
      'transmission_dipstick_smell',
      'underhood_equipment',
      'check_blow_by',
      'unhood_frame_check'
    ]
  ],
  ['tires_and_wheels', ['driver_front', 'driver_rear', 'passenger_rear', 'passenger_front']],
  ['interior', ['interior_pictures', 'interior_functional']],
  ['power_equipment', []],
  [
    'test_drive',
    [
      'how_engine_runs',
      'engine_rev_up',
      'engine_light_acceleration',
      'engine_medium_acceleration',
      'engine_heavy_acceleration',
      'any_engine_noise',
      'exhaust_sounds_like',
      'how_transmission_shifts',
      'transmission_light_acceleration',
      'transmission_medium_acceleration',
      'transmission_heavy_acceleration',
      'transmission_shudder',
      'three_point_turn',
      'engage_in_low_4x4',
      'engage_in_high_4x4'
    ]
  ],
  ['dealer_comments', []]
];

export const vehicleSectionsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSectionConfig: builder.query<SectionConfig, void>({
      query: () => ({
        url: 'vehicles/sections/config',
        method: 'GET'
      }),
      transformResponse: (response: any) => response.data,
      keepUnusedDataFor: 3600
    }),
    getSectionsOrder: builder.query<SectionsOrder, void>({
      query: () => ({
        url: 'vehicles/upload/section-order',
        method: 'GET'
      }),
      transformResponse: (r: any) => r.data || defaultSectionOrder,
      keepUnusedDataFor: 3600
    }),
    updateSectionsOrder: builder.mutation<void, SectionsOrder>({
      query: sectionOrder => ({
        url: 'vehicles/upload/section-order',
        method: 'PUT',
        body: sectionOrder
      })
    })
  }),
  overrideExisting: true
});

export const {
  useGetSectionConfigQuery,
  useGetSectionsOrderQuery,
  useUpdateSectionsOrderMutation
} = vehicleSectionsApiSlice;

export default vehicleSectionsApiSlice;
