import styled from 'styled-components';
import { Field, useField } from 'react-final-form';
import { ImagePlaceholderIcon } from '../../../icons/ImagePlaceholder';
import { useDispatch } from 'react-redux';
import { setSkipNextAutosave } from '../../../../../services/uploadVehicle/uploadVehicleSlice';
import { NotesField } from '../../NotesField';
import { UpsertImagePayload, useUpsertSectionImageMutation } from '../../../../../services/api/upload-vehicle';
import { useParams } from 'react-router-dom';
import { ImageField } from '../../../types';
import { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { snackShow } from '../../../../../actions';
import { useGetVehicleId } from '../../../hooks/useGetVehicleId';
import { RenderDamagedCheckbox } from './components/DamagedCheckbox';
import { cutTextElipsis } from '../../../../../utils';

const ImageWrapper = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: rgba(217, 217, 217, 1);
  justify-content: center;
  position: relative;
`;

const LoadingSpin = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ImageContainer = styled.div`
  height: 130px;
  width: 100%;
  position: relative;
`;

const ImageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 22px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
`;

const ImageTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;
  text-align: center;
  color: #fff;
`;

const NotesIconWrapper = styled.div`
  position: absolute;
  background: #fff;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const LabelPlaceholder = styled.span`
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 16.41px;
  text-align: center;
  color: #fff;
  opacity: 0.6;
`;

export const ImageComponent = ({ name, renderDamageCheckbox }: { name: string; renderDamageCheckbox: boolean }) => {
  const dispatch = useDispatch();

  const [localImage, setLocalImage] = useState<File | null>(null);

  const { id: vehicleId } = useParams<{ id: string }>();

  const { input } = useField<ImageField>(name);

  const [sectionName, subSectionName] = name.split('.');

  const [upsertImage, { isLoading }] = useUpsertSectionImageMutation();

  const handleImageChange = async (file?: File) => {
    if (!file) return null;

    const payload: UpsertImagePayload = {
      file,
      imageType: input.value.type,
      vehicleId
    };

    if (input.value.label) {
      payload.label = input.value.label;
    }

    if (typeof input.value.is_damaged === 'boolean') {
      payload.isDamaged = input.value.is_damaged;
    }

    if (input.value.id) {
      payload.imageId = input.value.id;
    } else {
      payload.sectionName = sectionName;
      payload.subSectionName = subSectionName;
    }

    const response = await upsertImage(payload);
    if ('data' in response) {
      dispatch(setSkipNextAutosave(true));
      input.onChange(response.data.data);
    } else {
      dispatch(
        snackShow({
          message: (response.error as any)?.data?.data?.message || 'Uploading image failed',
          type: 'error'
        })
      );
    }

    return null;
  };

  return (
    <ImageContainer>
      <Field name={`${name}.url`} type="file">
        {props => {
          return (
            <ImageWrapper htmlFor={`${name}-upload`}>
              <RenderImage inputImage={props.input.value} localImage={localImage} isUploading={isLoading} />
              <input
                id={`${name}-upload`}
                style={{ display: 'none' }}
                type="file"
                onChange={async e => {
                  const file = e.target?.files?.[0];
                  if (!file) return;
                  setLocalImage(file);
                  await handleImageChange(e.target?.files?.[0]);
                  setLocalImage(null);
                }}
              />
            </ImageWrapper>
          );
        }}
      </Field>
      <RenderLabel name={name} />
      <NotesIconWrapper>
        <NotesField name={name} />
      </NotesIconWrapper>
      {renderDamageCheckbox && <RenderDamagedCheckbox name={name} />}
    </ImageContainer>
  );
};

type RenderLabelProps = {
  name: string;
};

const RenderLabel = ({ name }: RenderLabelProps) => {
  const {
    input: { value: label }
  } = useField(`${name}.label`);

  const {
    input: { value: notes }
  } = useField(`${name}.notes`);

  if (!label && !notes) {
    return (
      <ImageTitleContainer>
        <LabelPlaceholder>Add comment</LabelPlaceholder>
      </ImageTitleContainer>
    );
  }

  const renderValue = [label, cutTextElipsis(notes, 3)].filter(Boolean).join(' - ');
  return (
    <ImageTitleContainer>
      <ImageTitle>{renderValue}</ImageTitle>
    </ImageTitleContainer>
  );
};

type RenderImageProps = {
  inputImage: string;
  localImage: File | null;
  isUploading: boolean;
};

const RenderImage = ({ inputImage, localImage, isUploading }: RenderImageProps) => {
  if (localImage) {
    return (
      <>
        <StyledImage src={URL.createObjectURL(localImage)} />
        {isUploading && (
          <LoadingSpin>
            <CircularProgress />
          </LoadingSpin>
        )}
      </>
    );
  }

  if (inputImage) {
    return <StyledImage src={inputImage} />;
  }

  return <ImagePlaceholderIcon />;
};
