import { Field, useField } from 'react-final-form';
import { Grade, GradeColor } from 'services/api/vehicle-sections/types/common';
import styled from 'styled-components';
import { useUpdateVehicleSectionMutation } from '../../../../services/api/upload-vehicle';
import { useGetVehicleId } from '../../hooks/useGetVehicleId';
import { iconMap } from './selectedIcons';

const Wrapper = styled.div`
  gap: 4px;
  display: flex;
`;

const colorMap: Record<GradeColor, string> = {
  red: 'rgba(175, 41, 48, 1)',
  yellow: 'rgba(243, 175, 103, 1)',
  green: 'rgba(71, 151, 71, 1)',
  blue: 'rgba(57, 148, 222, 1)',
  dark_blue: 'rgba(34, 90, 145, 1)',
  light_red: 'rgba(226, 110, 116, 1)',
  dark_green: 'rgba(23, 118, 23, 1)'
};

const GradeButton = styled.div<{ colorType: GradeColor }>`
  position: relative;
  background: ${props => colorMap[props.colorType]};
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  padding: 5px 8px;
  color: #fff;
  cursor: pointer;
  font-weight: 400;
`;

const SelectedIconWrapper = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  width: 9px;
  height: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GradesComponent = ({ grades, name }: { grades: Grade[]; name: string }) => {
  return (
    <Field name={`${name}.grade`}>
      {() => (
        <Wrapper>
          {grades.map(grade => (
            <GradeField key={grade.slug} grade={grade} name={name} />
          ))}
        </Wrapper>
      )}
    </Field>
  );
};

type GradeFieldProps = {
  grade: Grade;
  name: string;
};

const GradeField = ({ grade, name }: GradeFieldProps) => {
  const { input } = useField(`${name}.grade`);

  const [updateSection] = useUpdateVehicleSectionMutation();
  const vehicleId = useGetVehicleId();

  const handleGradeClick = () => {
    input.onChange(grade.slug);
    const [sectionName, subSectionName] = name.split('.');
    updateSection({
      vehicleId,
      payload: {
        sectionName,
        subSectionName,
        grade: grade.slug
      }
    });
  };

  const SelectedIcon = iconMap[grade.color];

  return (
    <GradeButton key={grade.slug} onClick={handleGradeClick} colorType={grade.color}>
      {grade.label}
      {input.value === grade.slug && (
        <SelectedIconWrapper>
          <SelectedIcon />
        </SelectedIconWrapper>
      )}
    </GradeButton>
  );
};
