import { Field, useField, useForm } from 'react-final-form';
import { RegularSectionContainer } from '../components/RegularSectionContainer';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../..';
import { FormTextField, LoaderModal } from '../../../components';
import { composeValidators, required as validateRequired, vinLength } from 'utils/validators';
import { Button, FormControlLabel, Radio, withStyles } from '@material-ui/core';
import InlineFormRadioGroup from '../../Auth/Registration/components/InlineFormRadioGroup';
import {
  useChangeVinMutation,
  useGetVinInfoByVehicleIdQuery,
  useUploadVehicleMutation
} from '../../../services/api/upload-vehicle';
import { useHistory } from 'react-router-dom';
import { modalsHide, modalsToggle } from '../../../actions';
import { useGetVehicleId } from '../hooks/useGetVehicleId';
import { setSkipNextAutosave } from '../../../services/uploadVehicle/uploadVehicleSlice';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: baseline;
  }
`;

const styles = (theme: any) => ({
  formHolder: {
    marginTop: 21,
    marginBottom: 24
  },
  vinField: {
    width: '100%'
    // [theme.breakpoints.up('sm')]: {
    //   maxWidth: '50%'
    // },
    // [theme.breakpoints.down('sm')]: {
    //   width: 'calc(100% - 120px)'
    // }
  },
  spanHolder: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  spanOr: {
    marginLeft: 4,
    marginRight: 16
  },

  loader: {
    alignSelf: 'flex-end'
  }
});

const AuctionTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  margin-left: 8px;

  @media (max-width: 567px) {
    margin-left: 0;
  }
`;

const upper = (value: string) => value && value.toUpperCase();

const PatchedFormTextField = FormTextField as any;

const VinSection = ({ classes = {} }: any) => {
  const user = useSelector((state: RootState) => state.user.user);
  const vehicleId = useGetVehicleId();

  return (
    <RegularSectionContainer title="VIN Number">
      <LoaderModal modalId="uploadLoaderModal" loaderText="Creating vehicle..." />
      <Wrapper>
        <Field
          name="vin"
          id="vin"
          label="Enter VIN Number"
          component={PatchedFormTextField}
          type="text"
          validate={composeValidators(validateRequired, vinLength)}
          className={classes.vinField}
          normalize={upper}
        />
        {user.role === 'admin' && (
          <AuctionTypeWrapper>
            <div>Auctions</div>
            <Field name="auction_type" component={InlineFormRadioGroup as any}>
              {/**disabled=vehicle.status === 'active' */}
              <FormControlLabel disabled={false} value={'autoaxess'} control={<Radio />} label="AutoAxess" />
              <FormControlLabel disabled={false} value={'frontline'} control={<Radio />} label="Frontline" />
            </Field>
          </AuctionTypeWrapper>
        )}
        {!!vehicleId ? <ChangeButton /> : <SubmitButton />}
      </Wrapper>
    </RegularSectionContainer>
  );
};

const SubmitButton = () => {
  const [uploadVehicleByVin] = useUploadVehicleMutation();

  const {
    input: { value: seller_id }
  } = useField('seller_id', { subscription: { value: true } });

  const {
    input: { value: vin }
  } = useField('vin', { subscription: { value: true } });

  const history = useHistory();

  const dispatch = useDispatch();

  const handleSubmitVin = async () => {
    if (!seller_id || !vin) return;

    try {
      dispatch(modalsToggle('uploadLoaderModal'));

      const response = await uploadVehicleByVin({
        vin,
        seller_id,
        upload_type: 'automatic'
      });
      dispatch(modalsHide('uploadLoaderModal'));
      if ((response as any).data) {
        const id = (response as any).data.id;
        history.push(`/upload-vehicle/${id}`);
      }
    } catch (error) {
      dispatch(modalsHide('uploadLoaderModal'));
    }
  };

  return (
    <Button variant="contained" color="primary" onClick={handleSubmitVin}>
      SUBMIT
    </Button>
  );
};

const ChangeButton = () => {
  const vehicleId = useGetVehicleId();
  const [changeVin, { isLoading }] = useChangeVinMutation();
  const dispatch = useDispatch();
  const { refetch } = useGetVinInfoByVehicleIdQuery(vehicleId);
  const {
    input: { value: vin }
  } = useField('vin');

  const form = useForm();

  const handleChangeVin = async () => {
    const response = await changeVin({ vehicleId, vin });

    if ('data' in response) {
      const vehicle = response.data.data;

      dispatch(setSkipNextAutosave(true));
      form.batch(() => {
        form.change('vin', vehicle.vin);
        form.change('trim', vehicle.trim);
        form.change('transmission', vehicle.transmission);
        form.change('drive_train', vehicle.drive_train);
        form.change('ext_color', vehicle.ext_color);
        form.change('engine', vehicle.engine);
        form.change('options', vehicle.options);
      });
      await refetch();
    }
  };

  return (
    <>
      <Button variant="contained" color="secondary" onClick={handleChangeVin}>
        CHANGE
      </Button>
      <LoaderModal loaderText="Updating vin..." forceOpen={isLoading} modalId={'vehicle_loading'} />
    </>
  );
};

export default withStyles(styles)(VinSection);
