import { User } from './user';

export type VehicleOption = {
  id: number;
  vehicle_id: number;
  bluebook_option_id: number;
  option_name: string;
  is_typical: boolean;
};

export type VehicleSectionGrade = {
  id: number;
  slug: string;
  color: string;
  label: string;
};

export type VehicleSectionImage = {
  id: number;
  url: string;
  url_thumb: string;
  label?: string;
  notes?: string;
  type: string | 'user_defined';
};

type Section = {
  id: number;
  name: string;
  sub_section_name: string;
  type: 'checkbox' | 'block';
};

export type VehicleSectionCheckbox = {
  id: number;
  label: string;
  notes?: string;
  selected: boolean;
};

export type VehicleSection = {
  id: number;
  grade?: VehicleSectionGrade;
  notes?: string;
  images: VehicleSectionImage[];
  section: Section;
  checkboxes?: VehicleSectionCheckbox[];
};

export type Vehicle = {
  id: number;
  vin: string;
  year: number;
  make: string;
  model: string;
  trim: string;
  transmission: string;
  ext_color: string;
  engine: string;
  drive_train: string;
  options: VehicleOption[];
  mileage: number;
  bluebook_auction_value_good: number;
  bluebook_mileage_adjustment: number;
  bluebook_mileage: number;
  bluebook_value: number;
  vehicleSections: VehicleSection[];
  is_guaranteed: boolean;
  [x: string]: any;
};

export enum AuctionType {
  AUTOAXESS = 'autoaxess',
  FRONTLINE = 'frontline'
}

export type Auction = {
  id: number;
  location_id: number;
  type: AuctionType;
  status: string;
  date_end: string;
};

export enum OfferAuctionStatus {
  ENDED = 'ended',
  PRIVATE_BIDS = 'private_bids',
  PUBLIC_OFFERS = 'public_offers'
}

export enum OfferStatus {
  SENT = 'sent',
  SELLER_ACCEPTED = 'seller_accepted',
  ADMIN_ACCEPTED = 'admin_accepted',
  REJECTED = 'rejected',
  COUNTERED = 'countered',
  ADMIN_COUNTERED = 'admin_countered',
  PUBLIC = 'public'
}

export type Offer = {
  id: number;
  offer_auction_id: number;
  sender_id: number;
  sender: User;
  amount: number;
  notes: string;
  status: OfferStatus;
  created_at: string;
  is_admin: boolean;
  type: 'private' | 'public';
  is_negotiation_stopped: boolean;
  edited_at?: string;
  is_edited: boolean;
};

export type OfferAuctionBid = {
  id: number;
  amount: number;
  user_id: number;
  bidder: User;
  status: string;
  created_at: string;
  updated_at: string;
};

export type OfferAuction = {
  id: number; // same as vehicle_id
  status: OfferAuctionStatus;
  top_bidder_id: number;
  vehicle_id: number;
  privateOffers: Offer[];
  created_at: string;
  starting_bid: number;
  offerBids: OfferAuctionBid[];
  offerProxyBid?: OfferAuctionBid;
};
