import { useGetSectionConfigQuery } from '../../../services/api/vehicle-sections';
import { SectionConfig } from '../../../services/api/vehicle-sections/types';
import { CommonSectionFields, SubSection } from '../../../services/api/vehicle-sections/types/common';

export const useGetSubSectionConfig = (sectionName: keyof SectionConfig, subSectionName: string): SubSection | null => {
  const { data: config } = useGetSectionConfigQuery();

  if (!config) return null;

  return config[sectionName].subsections[subSectionName] || null;
};

export const useGetSectionConfigById = (sectionName: keyof SectionConfig): CommonSectionFields | null => {
  const { data: config } = useGetSectionConfigQuery();

  if (!config) return null;

  return config[sectionName];
};
