import apiSlice from '..';
import { Vehicle } from '../../../types/vehicle';
import { convertToFormData } from '../../../utils/api';
import { CheckboxField } from '../vehicle-sections/types/common';
import { BlueBookInfo } from './types';

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

type AddCheckboxArgs = {
  vehicleId: number;
  sectionName: string;
  payload: { selected: boolean; label: string };
};

type UpdateCheckboxArgs = {
  checkboxId: number;
  payload: { selected?: boolean; notes?: string; label?: string };
};

export type ImageField = {
  id: number;
  label: string;
  notes: string;
  url: string;
  url_thumb: string;
  type: string;
};

export type UpdateSectionPayload = {
  sectionName: string;
  subSectionName?: string;
  notes?: string;
  grade?: string;
};

export type SaveImagePayload = {
  sectionName: string;
  subSectionName: string;
  vehicleId: number | string;
  files: File[];
};

export type UpsertImagePayload = {
  sectionName?: string;
  subSectionName?: string;
  vehicleId: number | string;
  file?: File;
  label?: string;
  notes?: string;
  imageType: string;
  imageId?: number;
  isDamaged?: boolean;
};

export const uploadVehicleApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getVinInfoByVehicleId: builder.query<BlueBookInfo[], number>({
      query: vehicleId => ({
        url: `vehicles/upload/vin-info/${vehicleId}`,
        method: 'GET'
      }),
      transformResponse: (r: any) => r.data
    }),
    getVehicleById: builder.query<Vehicle, number>({
      query: id => ({
        url: `vehicles/${id}`,
        method: 'GET'
      }),
      transformResponse: (r: any) => r.data
    }),
    uploadVehicle: builder.mutation<Vehicle, { vin: string; seller_id: number; upload_type?: string }>({
      query: ({ vin, seller_id, upload_type }) => ({
        url: `vehicles/upload?upload_type=${upload_type}`,
        method: 'POST',
        body: {
          vin,
          seller_id
        }
      }),
      transformResponse: (r: any) => r.data
    }),
    updateVehicleField: builder.mutation<{ data: Vehicle }, { payload: DeepPartial<Vehicle>; id: number }>({
      query: ({ payload, id }) => ({
        url: `vehicles/upload/${id}`,
        body: payload,
        method: 'PATCH'
      })
    }),
    estimateCarPrice: builder.mutation<{ data: Partial<Vehicle> }, { vehicleId: number; mileage: number }>({
      query: ({ vehicleId, mileage }) => ({
        url: `vehicles/upload/${vehicleId}/estimate-price`,
        body: { mileage },
        method: 'POST'
      })
    }),
    saveSectionImages: builder.mutation<{ data: ImageField[] }, SaveImagePayload>({
      query: ({ vehicleId, sectionName, subSectionName, files }) => {
        const formData = new FormData();
        for (const file of files) {
          formData.append('files', file);
        }

        formData.append('sectionName', sectionName);
        formData.append('subSectionName', subSectionName);

        return {
          url: `vehicles/upload/images/${vehicleId}`,
          method: 'POST',
          body: formData,
          formData: true
        };
      }
    }),
    upsertSectionImage: builder.mutation<{ data: ImageField }, UpsertImagePayload>({
      query: ({ vehicleId, ...payload }) => {
        const formData = convertToFormData(payload);

        return {
          url: `vehicles/upload/images/upsert/${vehicleId}`,
          method: 'POST',
          body: formData,
          formData: true
        };
      }
    }),
    updateSectionImageDamaged: builder.mutation<void, { imageId: number; isDamaged: boolean }>({
      query: ({ imageId, isDamaged }) => ({
        url: `vehicles/upload/images/${imageId}/damaged`,
        method: 'PATCH',
        body: { isDamaged }
      })
    }),
    deleteSectionImage: builder.mutation<void, number>({
      query: id => ({
        url: `vehicles/upload/images/${id}`,
        method: 'DELETE'
      })
    }),
    updateVehicleSection: builder.mutation<void, { vehicleId: number; payload: UpdateSectionPayload }>({
      query: ({ vehicleId, payload }) => ({
        url: `vehicles/upload/sections/${vehicleId}`,
        method: 'PATCH',
        body: payload
      })
    }),
    addCheckbox: builder.mutation<{ data: CheckboxField }, AddCheckboxArgs>({
      query: ({ vehicleId, sectionName, payload }) => ({
        url: `vehicles/upload/${vehicleId}/${sectionName}/checkboxes`,
        method: 'POST',
        body: payload
      })
    }),
    updateCheckbox: builder.mutation<void, UpdateCheckboxArgs>({
      query: ({ checkboxId, payload }) => ({
        url: `vehicles/upload/checkboxes/${checkboxId}`,
        method: 'PATCH',
        body: payload
      })
    }),
    removeCheckbox: builder.mutation<void, number>({
      query: checkboxId => ({
        url: `vehicles/upload/checkboxes/${checkboxId}`,
        method: 'DELETE'
      })
    }),
    changeVin: builder.mutation<{ data: Vehicle }, { vehicleId: number; vin: string }>({
      query: ({ vehicleId, vin }) => ({
        url: `vehicles/change-vin/${vehicleId}`,
        method: 'POST',
        body: { vin }
      })
    }),
    submitVehicle: builder.mutation<void, { vehicleId: number; auctionType: string }>({
      query: ({ vehicleId, auctionType }) => ({
        url: `vehicles/upload/submit/${vehicleId}`,
        method: 'POST',
        body: { auctionType }
      })
    })
  }),
  overrideExisting: true
});

export const {
  useGetVehicleByIdQuery,
  useUploadVehicleMutation,
  useUpdateVehicleFieldMutation,
  useEstimateCarPriceMutation,
  useUpdateVehicleSectionMutation,
  useSaveSectionImagesMutation,
  useUpsertSectionImageMutation,
  useDeleteSectionImageMutation,
  useAddCheckboxMutation,
  useUpdateCheckboxMutation,
  useRemoveCheckboxMutation,
  useChangeVinMutation,
  useGetVinInfoByVehicleIdQuery,
  useUpdateSectionImageDamagedMutation,
  useSubmitVehicleMutation
} = uploadVehicleApiSlice;

export default uploadVehicleApiSlice;
