import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { SectionConfig } from '../api/vehicle-sections/types';

export type LocalImage = {
  id: string;
  serverId: number | null;
  file: File;
  isUploaded: boolean
}

type SubsectionState = {
  uploadDialogOpen: boolean;
  localImages: LocalImage[];
  activeDragImage: LocalImage | null
}

type UploadVehicleState = {
  subsectionState: SubsectionState | null;
  // TODO: if we want to keep multiple states then do:
  // subsectionState: Record<string, SubsectionState>
  activeSubsection: string | null;
  openedNotesDialog: string | null;
  skipNextAutosave: boolean
}

const initialState: UploadVehicleState = {
  subsectionState: null,
  activeSubsection: null,
  openedNotesDialog: null,
  skipNextAutosave: false
};

const generateId = () => {
  if (self && self.crypto && typeof self.crypto.randomUUID === 'function') {
    return self.crypto.randomUUID()
  }

  return String(Math.random())
}

const uploadVehicleSlice = createSlice({
  name: 'uploadVehicle',
  initialState,
  reducers: {
    openSubsectionDialog: (state, action: PayloadAction<string>) => {
      state.activeSubsection = action.payload;
      state.subsectionState = {
        uploadDialogOpen: true,
        localImages: [],
        activeDragImage: null
      }
    },
    closeSubsectionDialog: (state, action: PayloadAction<string>) => {
      if (state.activeSubsection !== action.payload) return;
      state.activeSubsection = null
      state.subsectionState = null
    },
    addLocalImages: (state, action: PayloadAction<{ section: string; images: File[] | FileList }>) => {
      const { section, images } = action.payload
      if (state.activeSubsection !== section || !state.subsectionState) return;
      state.subsectionState.localImages = [...state.subsectionState.localImages, ...Array.from(images).map(file => ({
        id: generateId(),
        serverId: null,
        file,
        isUploaded: false
      }))]
    },
    replaceLocalImages: (state, action: PayloadAction<{ section: string; images: LocalImage[] }>) => {
      const { section, images } = action.payload
      if (state.activeSubsection !== section || !state.subsectionState) return;
      state.subsectionState.localImages = [...images]
    },
    removeLocalImage: (state, action: PayloadAction<{ section: string; index: number }>) => {
      const { section, index } = action.payload
      if (state.activeSubsection !== section || !state.subsectionState) return;
      state.subsectionState.localImages.splice(index, 1)
    },
    setUploadedLocalImage: (state, action: PayloadAction<{ section: string, imageId: string, serverId: number }>) => {
      const { section, imageId, serverId } = action.payload
      if (state.activeSubsection !== section || !state.subsectionState) return;
      const image = state.subsectionState.localImages.find(e => e.id === imageId);
      if (!image) return;
      image.serverId = serverId
      image.isUploaded = true
    },
    setActiveDragImage: (state, action: PayloadAction<{ section: string; image: LocalImage }>) => {
      const { section, image } = action.payload
      if (state.activeSubsection !== section || !state.subsectionState) return;
      state.subsectionState.activeDragImage = image;
    },
    removeActiveDragImage: (state, action: PayloadAction<string>) => {
      if (state.activeSubsection !== action.payload || !state.subsectionState) return;
      state.subsectionState.activeDragImage = null
    },
  
    setSkipNextAutosave: (state, action: PayloadAction<boolean>) => {
      state.skipNextAutosave = action.payload
    },
    openNotesDialog: (state, action: PayloadAction<string>) => {
      state.openedNotesDialog = action.payload
    },
    closeNotesDialog: (state) => {
      state.openedNotesDialog = null
    }
  }
});

export const useIsImageDialogOpen = (name: string) => {
  const { activeSubsection, subsectionState } = useSelector<{ uploadVehicle: UploadVehicleState }, UploadVehicleState>((state) => state.uploadVehicle)

  if (!subsectionState || activeSubsection !== name) return false
  return subsectionState.uploadDialogOpen
}

export const useLocalImages = (name: string) => {
  const { activeSubsection, subsectionState } = useSelector<{ uploadVehicle: UploadVehicleState }, UploadVehicleState>((state) => state.uploadVehicle)

  if (!subsectionState || activeSubsection !== name) return []
  return subsectionState.localImages
}

export const useActiveDragImage = (name: string) => {
  const { activeSubsection, subsectionState } = useSelector<{ uploadVehicle: UploadVehicleState }, UploadVehicleState>((state) => state.uploadVehicle)

  if (!subsectionState || activeSubsection !== name) return null
  return subsectionState.activeDragImage
}

export const useOpenedNotesDialog = () => {
  const openedNotesDialog = useSelector<{ uploadVehicle: UploadVehicleState }, string | null>((state) => state.uploadVehicle.openedNotesDialog)
  return openedNotesDialog
}

export const { setSkipNextAutosave, openSubsectionDialog, closeSubsectionDialog, addLocalImages, removeLocalImage, setActiveDragImage, removeActiveDragImage, setUploadedLocalImage, replaceLocalImages, openNotesDialog, closeNotesDialog } = uploadVehicleSlice.actions
export default uploadVehicleSlice;
