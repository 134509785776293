import { Field } from 'react-final-form';
import styled from 'styled-components';
import { SoundIcon } from '../../../icons/SoundIcon';
import { AddImageIcon } from '../../../icons/AddImageIcon';
import { SubSection } from '../../../../../services/api/vehicle-sections/types/common';
import { openSubsectionDialog } from '../../../../../services/uploadVehicle/uploadVehicleSlice';
import { useDispatch } from 'react-redux';
import { FooterNotesField } from './FooterNotesField';

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const IconWrapper = styled.div`
  background: rgba(34, 34, 34, 0.04);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
`;

type Props = {
  name: string;
  subSectionConfig: SubSection;
};

export const Footer = ({ name, subSectionConfig }: Props) => {
  const dispatch = useDispatch();
  const openImageUploadDialog = () => dispatch(openSubsectionDialog(name));

  return (
    <Wrapper>
      <Field name={`${name}.notes`}>{props => <FooterNotesField name={name} />}</Field>
      {subSectionConfig.withSound && (
        <IconWrapper>
          <SoundIcon />
        </IconWrapper>
      )}
      <IconWrapper onClick={openImageUploadDialog}>
        <AddImageIcon />
      </IconWrapper>
    </Wrapper>
  );
};
