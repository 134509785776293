import styled from 'styled-components';
import {
  addLocalImages,
  removeLocalImage,
  useLocalImages
} from '../../../../../services/uploadVehicle/uploadVehicleSlice';
import { DeleteImageButton } from '../DeleteImageButton';
import { ImageConfig } from '../../../../../services/api/vehicle-sections/types/common';
import { useDispatch } from 'react-redux';
import { ImagePlaceholderIcon } from '../../../icons/ImagePlaceholder';
import { DraggableImage } from './DraggableImage';
import { snackShow } from '../../../../../actions';
import { useFieldArray } from 'react-final-form-arrays';

const Label = styled.span`
  margin-top: 24px;
  font-size: 14px;
  line-height: 16.41px;
  color: rgba(102, 102, 102, 1);
`;

const ImagesWrapper = styled.div`
  margin: 12px 0;
  display: grid;
  gap: 8px;
  grid-template-rows: auto;
  grid-template-columns: repeat(2, 144px);

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 144px);
  }
`;

const AddImageWrapper = styled.label`
  width: 144px;
  height: 108px;
  display: flex;
  align-items: center;
  background: rgba(217, 217, 217, 1);
  justify-content: center;
  position: relative;
`;

type Props = {
  name: string;
  imageConfig: ImageConfig;
};

export const LocalImages = ({ name, imageConfig }: Props) => {
  const localImages = useLocalImages(name);
  const dispatch = useDispatch();

  const { fields } = useFieldArray(`${name}.images`, { subscription: { value: true } });

  const handleAddImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const userDefined = fields.value.filter(v => v.type === 'user_defined');
    if (userDefined.length + localImages.length + e.target.files.length > imageConfig.maxImages) {
      dispatch(snackShow({ message: `Can't upload more then ${imageConfig.maxImages} images`, type: 'error' }));
      return;
    }
    dispatch(addLocalImages({ section: name, images: e.target.files }));
  };

  return (
    <>
      <Label>From Library Uploades</Label>
      <ImagesWrapper>
        {localImages.map((img, i) => {
          return <DraggableImage key={img.id} img={img} i={i} section={name} />;
        })}
        {localImages.length < imageConfig.maxImages && (
          <AddImageWrapper htmlFor="local-upload">
            <input id={`local-upload`} style={{ display: 'none' }} type="file" multiple onChange={handleAddImages} />
            <ImagePlaceholderIcon />
          </AddImageWrapper>
        )}
      </ImagesWrapper>
    </>
  );
};
