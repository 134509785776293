import { useField } from 'react-final-form';
import styled from 'styled-components';
import { NotesIcon } from '../../../icons/NotesIcon';
import { TextField } from '@material-ui/core';
import { useUpdateVehicleSectionMutation } from '../../../../../services/api/upload-vehicle';
import { useGetVehicleId } from '../../../hooks/useGetVehicleId';
import { useEffect, useRef, useState } from 'react';
import { SendIcon } from '../../../icons/SendIcon';
import { EditNotesIcon } from '../../../icons/EditNotesIcon';
import { useDispatch } from 'react-redux';
import { snackShow } from '../../../../../actions';

type NotesFieldProps = {
  name: string;
};

const NotesActionWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotesWrapper = styled.div<{ smallPadding: boolean; isEditing?: boolean }>`
  background: ${props => (props.isEditing ? '#fff' : 'rgba(34, 34, 34, 0.04)')};
  border: ${props => (props.isEditing ? '1px solid rgba(57, 148, 222, 1)' : 'none')};
  border-radius: 8px;
  flex-grow: 1;
  padding-left: ${props => {
    if (props.isEditing) {
      return '16px';
    }
    return props.smallPadding ? '8px' : '28px';
  }};
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 13px;
  cursor: pointer;
`;

const StyledTextField = styled(TextField)`
  flex-grow: 1;

  & * input {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    padding: 0;
    width: 100%;
  }
`;

export const FooterNotesField = ({ name }: NotesFieldProps) => {
  const { input } = useField(`${name}.notes`);

  const dispatch = useDispatch();

  const hasSavedValue = !!input.value;

  const [localInput, setLocalInput] = useState(input.value || '');
  const [inputFocused, setIsInputFocused] = useState(false);

  const ref = useRef<any>();

  const vehicleId = useGetVehicleId();
  const [updateSection] = useUpdateVehicleSectionMutation();

  const [sectionName, subSectionName] = name.split('.');

  const saveNotes = () => {
    const parsedLocalInput = localInput.trim();
    if (parsedLocalInput.length > 50) {
      dispatch(snackShow({ message: `Max notes length is 50`, type: 'error' }));
      return;
    }
    setIsInputFocused(false);
    input.onChange(parsedLocalInput);
    updateSection({
      vehicleId,
      payload: {
        sectionName,
        subSectionName,
        notes: parsedLocalInput
      }
    });
  };

  useEffect(() => setLocalInput(input.value), [input.value]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key !== 'Enter') return;
    saveNotes();
  };

  const startEditing = () => {
    if (ref.current) {
      setIsInputFocused(true);
      ref.current.focus();
    }
  };

  const notesAction = !inputFocused && hasSavedValue ? startEditing : saveNotes;

  const isTiresAndWheels = name.startsWith('tires_and_wheels');

  return (
    <NotesWrapper isEditing={inputFocused} smallPadding={isTiresAndWheels}>
      {!inputFocused && <NotesIcon disableOuterBackground isActive={input.value} />}
      <StyledTextField
        inputRef={ref}
        value={localInput}
        onKeyDown={handleKeyDown}
        onClick={() => {
          if (hasSavedValue || inputFocused) return;
          startEditing();
        }}
        onChange={e => {
          setLocalInput(e.target.value);
        }}
        InputProps={{ disableUnderline: true, readOnly: !inputFocused }}
        placeholder="Add Notes..."
      />
      <NotesActionWrapper onClick={notesAction}>
        {!inputFocused && hasSavedValue ? <EditNotesIcon /> : <SendIcon isActive={inputFocused} />}
      </NotesActionWrapper>
    </NotesWrapper>
  );
};
