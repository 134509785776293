import { Form } from 'react-final-form';
import { defaultSectionOrder, useGetSectionConfigQuery } from '../../services/api/vehicle-sections';
import { Header } from './header';
import styled from 'styled-components';
import { LoaderModal, PageWrapper } from '../../components';
import { useIsUserEditingVehicleQuery } from '../../services/api';
import { AnotherUserEditing } from '../../components/AnotherUserEditing/AnotherUserEditing';
import { SellerSection } from './sections/SellerSection';
import { useIsSellerLocked } from '../../services/sellerLock/sellerLockSlice';
import VinSection from './sections/VinSection';
import arrayMutators from 'final-form-arrays';
import { ImageConfig } from '../../services/api/vehicle-sections/types/common';
import { MovableSections } from './sections/movable/MovableSections';
import { AddNoteDialog } from './components/AddNoteDialog';
import { VehicleInformation } from './sections/VehicleInformation';
import { useParams } from 'react-router-dom';
import { useGetVehicleByIdQuery } from '../../services/api/upload-vehicle';
import { Autosave } from './autosave';
import { VehicleSectionImage } from '../../types/vehicle';
import { SectionConfig } from '../../services/api/vehicle-sections/types';
import { DisclaimerSection } from './sections/Disclaimer';
import { Footer } from './sections/Footer';

const StyledPageWrapper = styled(PageWrapper)`
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1400px;
`;

const createInitialImageValues = (existingImages?: VehicleSectionImage[], imageConfig?: ImageConfig) => {
  const predefinedSlots =
    imageConfig?.predefinedSlots.map(({ label, slug }) => {
      const existingImage = existingImages?.find(i => i.type === slug);
      return (
        existingImage || {
          label: label,
          notes: '',
          type: slug || 'user_defined'
        }
      );
    }) || [];

  const userDefinedImages = existingImages?.filter(i => i.type === 'user_defined') || [];

  return [...predefinedSlots, ...userDefinedImages];
};

const createInitialCheckboxField = (values: string[]) => {
  return values.map(v => ({
    notes: '',
    value: v,
    isSelected: false
  }));
};

const useCreateSectionsFields = () => {
  const { data: sectionsConfig } = useGetSectionConfigQuery();
  const { id } = useParams<{ id: string }>();
  const { data: existingVehicle } = useGetVehicleByIdQuery(Number(id), { skip: !id });

  if (!sectionsConfig) return null;

  const createAdditionalInteriorSections = () => {
    const config = sectionsConfig.interior.subsections.interior_functional.additionalSections;
    const result: Record<string, any> = {};
    for (const key of Object.keys(config)) {
      const section =
        existingVehicle?.vehicleSections.find(
          e => e.section.name === 'interior' && e.section.sub_section_name === key
        ) || null;

      result[key] = {
        grade: section?.grade?.slug || null
      };
    }

    return result;
  };

  const createSubsections = (name: keyof SectionConfig, subSections: string[]) => {
    if (subSections.length === 0) {
      const section = existingVehicle?.vehicleSections.find(e => e.section.name === name) || null;
      const sectionConfig = sectionsConfig[name];
      console.log(section?.checkboxes, 'section?.checkboxes');
      return {
        [name]: {
          grade: section?.grade?.slug || null,
          checkboxes: section?.checkboxes || createInitialCheckboxField(sectionConfig.initialCheckboxFields || [])
        }
      };
    }

    const subsectionMap: Record<string, any> = {};

    for (const subSectionName of subSections) {
      const section =
        existingVehicle?.vehicleSections.find(
          e => e.section.name === name && e.section.sub_section_name === subSectionName
        ) || null;

      const sectionConfig = sectionsConfig[name].subsections[subSectionName];
      subsectionMap[subSectionName] = {
        images: createInitialImageValues(section?.images, sectionConfig.imageConfig),
        grade: section?.grade?.slug || null,
        notes: section?.notes || null
      };
    }

    return {
      [name]: subsectionMap
    };
  };

  let resultMap: Record<string, any> = {};

  for (const [section, subSection] of defaultSectionOrder) {
    if (section === 'dealer_comments') {
      continue;
    }
    resultMap = { ...resultMap, ...createSubsections(section, subSection) };
  }

  const additionalInteriorSections = createAdditionalInteriorSections();
  resultMap.interior = { ...resultMap.interior, ...additionalInteriorSections };

  return resultMap;
};

export default function CreateVehiclePage() {
  const { data: sectionsConfig } = useGetSectionConfigQuery();
  const { data: editingUser } = useIsUserEditingVehicleQuery(1, { skip: true });

  const { id } = useParams<{ id: string }>();

  const { data: existingVehicle, isLoading: vehicleLoading } = useGetVehicleByIdQuery(Number(id), { skip: !id });

  const sectionFields = useCreateSectionsFields();

  const isSellerLocked = useIsSellerLocked();

  if (!sectionsConfig || !sectionFields) return null;

  const initialSellerId = (() => {
    if (existingVehicle) {
      return existingVehicle.user_id;
    }

    if (!isSellerLocked) return null;
    const lockedId = localStorage.getItem('locked_seller');
    return Number(lockedId) || null;
  })();

  const initialValues = {
    seller_id: initialSellerId,
    vin: existingVehicle?.vin || '',
    year: existingVehicle?.year || '',
    make: existingVehicle?.make || '',
    model: existingVehicle?.model || '',
    trim: existingVehicle?.trim || '',
    engine: existingVehicle?.engine || '',
    ext_color: existingVehicle?.ext_color || '',
    drive_train: existingVehicle?.drive_train || '',
    transmission: existingVehicle?.transmission || '',
    starting_bid: existingVehicle?.starting_bid || 100,
    dealer_comments: existingVehicle?.dealer_comments || '',
    reserve_price: existingVehicle?.reserve_price || null,
    options: existingVehicle?.options || [],
    is_guaranteed: existingVehicle?.is_guaranteed || false,
    auction_type: 'autoaxess',
    mileage: existingVehicle?.mileage,
    bluebook_mileage: existingVehicle?.bluebook_mileage,
    bluebook_value: existingVehicle?.bluebook_value,
    bluebook_auction_value_good: existingVehicle?.bluebook_auction_value_good,
    bluebook_mileage_adjustment: existingVehicle?.bluebook_mileage_adjustment,
    ...sectionFields
  };

  const handleSubmit = () => {};

  return (
    <Form
      mutators={{
        ...arrayMutators
      }}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      subscription={{ submitting: true, submitFailed: true }}
    >
      {props => (
        <form onSubmit={props.handleSubmit} name="vehicle-upload">
          <Header />
          <StyledPageWrapper>
            {editingUser && <AnotherUserEditing user={editingUser} />}
            <SellerSection />
            <VinSection />
            {existingVehicle && (
              <>
                <VehicleInformation />
                <MovableSections />
                <DisclaimerSection />
                <Footer />
              </>
            )}
          </StyledPageWrapper>
          <AddNoteDialog />
          <Autosave />
          <LoaderModal forceOpen={vehicleLoading} modalId={'vehicle_loading'} />
        </form>
      )}
    </Form>
  );
}
