import { Field, useField, useForm } from 'react-final-form';
import { Button, Grid, MenuItem } from '@material-ui/core';
import { FormTextField, FormFormattedField, FormSelect } from 'components/Form';
import { normalizeFormattedField } from 'utils';
import { composeValidators, required, validateMinBid, validateReservePrice } from 'utils/validators';
import { useEstimateCarPriceMutation, useGetVinInfoByVehicleIdQuery } from '../../../../services/api/upload-vehicle';
import { BlueBookOption } from '../../../../services/api/upload-vehicle/types';
import { VehicleEquipment } from './Equipment';
import { LoaderModal } from '../../../../components';
import { useDispatch } from 'react-redux';
import { setSkipNextAutosave } from '../../../../services/uploadVehicle/uploadVehicleSlice';
import { useParams } from 'react-router-dom';
import { modalsHide, modalsToggle } from '../../../../actions';
import { useGetVehicleId } from '../../hooks/useGetVehicleId';
import styled from 'styled-components';
import { KbbIcon } from '../../icons/KbbIcon';

const PatchedFormTextField = FormTextField as any;

const minAmount = (value: any) => (value.toString().replace(/[\D]/g, '') >= 0 ? undefined : 'Min Amount is 0');

const KbbIconContainer = styled.div`
  position: absolute;
  right: 0;
  top: -60px;
`;

export const AutomaticVehicleInformationForm = () => {
  const form = useForm();
  const vehicleId = useGetVehicleId();
  const { data: vinInfos } = useGetVinInfoByVehicleIdQuery(vehicleId, { skip: !vehicleId });

  const [estimateCarPrice] = useEstimateCarPriceMutation();
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();

  const {
    input: { value: trim }
  } = useField('trim', { subscription: { value: true } });
  const {
    input: { value: bluebook_mileage }
  } = useField('bluebook_mileage', { subscription: { value: true } });
  const {
    input: { value: mileage }
  } = useField('mileage', { subscription: { value: true } });

  const trimOptions = vinInfos?.map(info => ({ optionName: info.trim }));
  const vinInfo = vinInfos?.find(info => info.trim === trim);

  const hasMileage = !!bluebook_mileage;

  const getOrUpdateMileageValues = async () => {
    if (!id) return;
    dispatch(setSkipNextAutosave(true));
    dispatch(modalsToggle('carEstimateLoaderModal'));
    const response = await estimateCarPrice({ vehicleId: Number(id), mileage: mileage });
    if ('data' in response && response.data.data) {
      const newValues = response.data.data;
      form.batch(() => {
        form.change('bluebook_auction_value_good', newValues.bluebook_auction_value_good);
        form.change('bluebook_mileage', newValues.bluebook_mileage);
        form.change('bluebook_mileage_adjustment', newValues.bluebook_mileage_adjustment);
        form.change('bluebook_value', newValues.bluebook_value);
        form.change('mileage', newValues.mileage);
      });
    }
    dispatch(modalsHide('carEstimateLoaderModal'));
  };

  return (
    <Grid style={{ position: 'relative' }} container spacing={16}>
      <LoaderModal modalId="loaderModal" loaderText="Updating vehicle configuration..." />
      <LoaderModal modalId="carEstimateLoaderModal" loaderText="Estimating vehicle price..." />
      <KbbIconContainer>
        <KbbIcon />
      </KbbIconContainer>
      <Grid item xs={12} sm={3}>
        <Field name="year" label="Year" component={PatchedFormTextField} fullWidth margin="none" />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field name="make" label="Make" component={PatchedFormTextField} fullWidth margin="none" />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field name="model" label="Model" component={PatchedFormTextField} fullWidth margin="none" />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Field name="trim" label="Trim" component={FormSelect as any} fullWidth margin="none">
          {trimOptions?.map((option, i) => (
            <MenuItem value={option.optionName} key={i}>
              {option.optionName}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name="engine"
          label="Engine"
          component={FormSelect as any}
          type="text"
          disabled={!trim}
          fullWidth
          margin="none"
          validate={required}
        >
          {vinInfo?.engines?.map((option, i) => (
            <MenuItem value={option.optionName} key={i}>
              {option.optionName}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          name="transmission"
          label="Transmission"
          component={FormSelect as any}
          type="text"
          disabled={!trim}
          fullWidth
          margin="none"
        >
          {vinInfo?.transmissions?.map((option, i) => (
            <MenuItem value={option.optionName} key={i}>
              {option.optionName}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          disabled={!trim}
          name="drive_train"
          label="Drive Train"
          component={FormSelect as any}
          type="text"
          fullWidth
          margin="none"
        >
          {vinInfo?.drive_trains?.map((option, i) => (
            <MenuItem value={option.optionName} key={i}>
              {option.optionName}
            </MenuItem>
          ))}
        </Field>
      </Grid>

      <Grid item sm={6} xs={12}>
        <Field
          disabled={!trim}
          name="ext_color"
          label="Ext. color"
          component={FormSelect as any}
          fullWidth
          margin="none"
        >
          {vinInfo?.colors?.map((option, i) => (
            <MenuItem value={option.optionName} key={i}>
              {option.optionName}
            </MenuItem>
          ))}
        </Field>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <Field
          className="text-field"
          name="starting_bid"
          label="Starting Bid"
          type="text"
          component={FormFormattedField}
          decimalScale={0}
          thousandSeparator
          prefix="$"
          fullWidth
          allowNegative={false}
          validate={validateMinBid}
          parse={normalizeFormattedField}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Field
          className="text-field"
          name="reserve_price"
          label="Reserve price"
          type="text"
          component={FormFormattedField}
          decimalScale={0}
          allowNegative={false}
          prefix="$"
          thousandSeparator
          fullWidth
          parse={normalizeFormattedField}
          validate={validateReservePrice}
        />
      </Grid>
      <VehicleEquipment name="options" />
      <Grid item container alignItems="flex-end" xs={12} style={{ gap: '8px' }}>
        <Grid item xs={12} sm={6} md={3}>
          <Field
            className="text-field"
            name="mileage"
            label="Enter Mileage"
            component={FormFormattedField}
            fullWidth
            parse={normalizeFormattedField}
            thousandSeparator
            validate={required}
          />
        </Grid>
        {hasMileage && (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                className="text-field"
                name="bluebook_value"
                label="Value Without Miles Adj"
                component={FormFormattedField}
                decimalScale={0}
                parse={normalizeFormattedField}
                prefix="$"
                thousandSeparator
                // type="text"
                fullWidth
                validate={composeValidators(required, minAmount)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Field
                className="text-field"
                name="bluebook_mileage"
                label="Blue Book® Lending Value"
                component={FormFormattedField}
                decimalScale={0}
                // type="text"
                prefix="$"
                thousandSeparator
                fullWidth
                parse={normalizeFormattedField}
                validate={composeValidators(required, minAmount)}
              />
            </Grid>
          </>
        )}
        <Button variant="contained" color="secondary" type="button" onClick={getOrUpdateMileageValues}>
          {!hasMileage ? 'Get Values' : 'Update Values'}
        </Button>
      </Grid>
    </Grid>
  );
};
