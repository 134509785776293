import styled from 'styled-components';
import { LocalImage } from '../../../../../services/uploadVehicle/uploadVehicleSlice';
import { Icon } from '@material-ui/core';

const Wrapper = styled.div`
  display: flex;
  width: 143px
  height: 109px;
  position: relative;
  border: 4px solid rgba(57, 148, 222, 1);
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const DragIconContainer = styled.div`
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
`;

export const DragItem = ({ item }: { item: LocalImage }) => {
  return (
    <Wrapper>
      <StyledImage src={URL.createObjectURL(item.file)} alt={item.id} />
      <DragIconContainer>
        <Icon>drag_indicator</Icon>
      </DragIconContainer>
    </Wrapper>
  );
};
