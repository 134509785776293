import { Button } from '@material-ui/core';
import { FormSpy, useField } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useState } from 'react';
import { ConfirmSubmitDialog } from '../components/ConfirmSubmitDialog';
import { useSubmitVehicleMutation } from '../../../services/api/upload-vehicle';
import { useGetVehicleId } from './useGetVehicleId';
import { useGetCurrentAuctionsQuery } from '../../../services/api/auctions-list';

const StyledButton = styled(Button)<{ uiType: 'cancel' | 'draft'; renderMobileIcon?: boolean }>`
  height: 43px;
  font-weight: 700;
  border-radius: 6px;
  width: ${props => (props.renderMobileIcon ? 'auto' : '140px')};

  .material-icons {
    display: ${props => (props.renderMobileIcon ? 'block' : 'none')};

    @media only screen and (min-width: 1024px) {
      display: none;
    }
  }

  span > span {
    @media only screen and (max-width: 1024px) {
      display: ${props => (props.renderMobileIcon ? 'none' : 'block')};
    }
  }

  @media (min-width: 1024px) {
    width: ${props => (props.uiType === 'cancel' ? '136px' : '210px')};
  }
`;

type Props = {
  renderMobileIcon?: boolean;
};

const CancelButton = ({ renderMobileIcon }: Props) => {
  const history = useHistory();
  const currentUser = useSelector((state: any) => state.user.user);
  const handleCancel = () => {
    const confirmation = window.confirm('Are you sure want to cancel vehicle creation?');
    if (confirmation) {
      history.push(`/${currentUser.role}/vehicles`);
    }
  };

  return (
    <StyledButton
      renderMobileIcon={renderMobileIcon}
      type="button"
      uiType="cancel"
      variant="contained"
      color="secondary"
      onClick={handleCancel}
    >
      <i className="material-icons">close</i>
      <span>Cancel</span>
    </StyledButton>
  );
};

const SaveAsDraftButton = ({ renderMobileIcon }: Props) => {
  const history = useHistory();
  const currentUser = useSelector((state: any) => state.user.user);

  const handleSaveAsDraft = () => {
    history.push(`/${currentUser.role}/vehicles`);
  };

  return (
    <StyledButton
      renderMobileIcon={renderMobileIcon}
      type="button"
      uiType="draft"
      variant="contained"
      color="primary"
      onClick={handleSaveAsDraft}
    >
      <i className="material-icons">save</i>
      <span>Save as draft</span>
    </StyledButton>
  );
};

const SubmitButtonSpy = ({ renderMobileIcon }: Props) => {
  return (
    <FormSpy subscription={{ hasValidationErrors: true }}>
      {({ hasValidationErrors }) => (
        <SubmitButton hasValidationErrors={hasValidationErrors} renderMobileIcon={renderMobileIcon} />
      )}
    </FormSpy>
  );
};

const SubmitButton = ({ renderMobileIcon, hasValidationErrors }: Props & { hasValidationErrors: boolean }) => {
  const [submitVehicle] = useSubmitVehicleMutation();
  const history = useHistory();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const vehicleId = useGetVehicleId();
  const {
    input: { value: auctionType }
  } = useField('auction_type', { subscription: { value: true } });
  const currentUser = useSelector((state: any) => state.user.user);

  const { data } = useGetCurrentAuctionsQuery();
  const { autoaxess: currentAutoaxessAuction, frontline: currentFlAuction } = data || {};
  const currentAuction = auctionType === 'autoaxess' ? currentAutoaxessAuction : currentFlAuction;

  const handleProceed = async () => {
    await submitVehicle({ vehicleId, auctionType });
    history.push(`/${currentUser.role}/vehicles`);
  };

  const handleSubmit = async () => {
    if (hasValidationErrors) {
      setConfirmModalOpen(true);
    } else {
      await handleProceed();
    }
  };

  return (
    <>
      <StyledButton
        renderMobileIcon={renderMobileIcon}
        type="button"
        uiType="draft"
        variant="contained"
        color="primary"
        disabled={!currentAuction}
        onClick={handleSubmit}
      >
        <i className="material-icons">save</i>
        <span>Submit</span>
      </StyledButton>
      <ConfirmSubmitDialog
        handleProceed={handleProceed}
        closeModal={() => setConfirmModalOpen(false)}
        isOpen={confirmModalOpen}
      />
    </>
  );
};

export const useGetFormControlButtons = () => {
  return { CancelButton, SaveAsDraftButton, SubmitButton: SubmitButtonSpy };
};
