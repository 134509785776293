import { SubSection } from 'services/api/vehicle-sections/types/common';
import styled from 'styled-components';
import { ImageComponent } from './ImageView';
import { FieldArray } from 'react-final-form-arrays';

type Props = {
  subSectionConfig: SubSection;
  name: string;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  color: #000;
`;

const ImagesWrapper = styled.div<{ numberOfImages: number }>`
  display: grid;
  grid-template-columns: ${props => {
    if (props.numberOfImages > 2) {
      return '1fr 1fr';
    }
    return '1fr';
  }};

  grid-template-rows: auto;
  gap: 8px;

  @media (min-width: 1024px) {
    grid-template-columns: ${props => {
      if (props.numberOfImages >= 4) {
        return '1fr 1fr 1fr 1fr';
      }
      const columns = props.numberOfImages % 4;
      return `repeat(${columns}, 1fr)`;
    }};
  }
`;

export const PhotoUploadComponent = ({ subSectionConfig, name }: Props) => {
  if (!subSectionConfig.imageConfig) return null;

  const isTiresAndWheelsSections = name.startsWith('tires_and_wheels');

  return (
    <>
      <FieldArray name={`${name}.images`}>
        {({ fields }) => (
          <Wrapper>
            {!!fields.length && fields.length > 0 && !isTiresAndWheelsSections && <Title>Upload Photos</Title>}
            <ImagesWrapper numberOfImages={!!fields.length ? fields.length : 0}>
              {fields.map((name, index) => (
                <ImageComponent
                  renderDamageCheckbox={
                    fields.value[index].id &&
                    fields.value[index].url &&
                    fields.value[index].type !== 'user_defined' &&
                    Boolean(subSectionConfig.imageConfig?.withDamageCheckbox)
                  }
                  key={name}
                  name={name}
                />
              ))}
            </ImagesWrapper>
          </Wrapper>
        )}
      </FieldArray>
    </>
  );
};
