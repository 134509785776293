import { useFieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import { Checkbox, TextField } from '@material-ui/core';
import { Field, useField } from 'react-final-form';
import { AddCheckboxIcon } from '../../icons/AddCheckboxIcon';
import { useAddCheckboxMutation } from '../../../../services/api/upload-vehicle';
import { useGetVehicleId } from '../../hooks/useGetVehicleId';
import { composeValidators, maxLength, required } from '../../../../utils/validators';
import { SectionConfig } from '../../../../services/api/vehicle-sections/types';
import { useDispatch } from 'react-redux';
import { snackShow } from '../../../../actions';

const EditingFieldWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(204, 204, 204, 1);
  padding-left: 40px;
  gap: 8px;
  align-items: center;
`;

const StyledTextField = styled(TextField)`
  flex-grow: 1;
`;

const AddButtonWrapper = styled.div`
  margin-left: auto;
  margin-top: 8px;
`;

const maxLength20 = maxLength(20);

type Props = {
  sectionId: keyof SectionConfig;
  name: string;
};

export const EditingCheckboxField = ({ sectionId, name }: Props) => {
  const [addNewCheckbox] = useAddCheckboxMutation();
  const vehicleId = useGetVehicleId();

  const { fields } = useFieldArray(`${sectionId}.checkboxes`, { subscription: { value: true } });
  const { input: editingInput } = useField(`${name}.isEditing`);
  const {
    input: { value: isSelected }
  } = useField(`${name}.selected`);
  const {
    input: { value: label, onChange: changeLabel },
    meta
  } = useField(`${name}.label`);
  const { input: idInput } = useField(`${name}.id`);

  const dispatch = useDispatch();

  const submitCheckbox = async () => {
    if (meta.error) {
      dispatch(snackShow({ message: `Label is too long`, type: 'error' }));
      return;
    }
    if (!label) {
      return;
    }
    const parsedLabel = label.trim();
    const isUnique = !fields.value.slice(0, -1).find(v => v.label === parsedLabel);
    if (!isUnique) {
      dispatch(snackShow({ message: `Duplicate label`, type: 'error' }));
      return;
    }
    editingInput.onChange(false);
    const response = await addNewCheckbox({
      vehicleId,
      sectionName: sectionId,
      payload: {
        selected: isSelected,
        label: parsedLabel
      }
    });

    if ('data' in response) {
      changeLabel(parsedLabel);
      idInput.onChange(response.data.data.id);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key !== 'Enter') return;
    if (!label || label.trim() === '') {
      const i = fields.value.length - 1;
      fields.remove(i);
    } else {
      submitCheckbox();
    }
  };

  return (
    <EditingFieldWrapper>
      <Field name={`${name}.selected`}>
        {props => (
          <Checkbox
            color="primary"
            style={{ width: '12px', height: '12px' }}
            checked={props.input.value}
            onChange={e => props.input.onChange(e.target.checked)}
          />
        )}
      </Field>
      <Field validate={composeValidators(maxLength20, required)} name={`${name}.label`}>
        {props => (
          <StyledTextField
            onKeyDown={handleKeyDown}
            InputProps={{
              disableUnderline: true
            }}
            {...props.meta}
            {...props.input}
          />
        )}
      </Field>

      <AddButtonWrapper onClick={submitCheckbox}>
        <AddCheckboxIcon />
      </AddButtonWrapper>
    </EditingFieldWrapper>
  );
};
