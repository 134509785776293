import { UpsertImagePayload } from '../../../services/api/upload-vehicle';
import { ImageField } from '../types';

export const createUpsertImagePayload = (
  vehicleId: string | number,
  name: string,
  formField?: ImageField,
  file?: File,
  notes?: string,
  label?: string
) => {
  const payload: UpsertImagePayload = {
    vehicleId,
    imageType: formField?.type || 'user_defined'
  };

  if (notes != null) {
    payload.notes = notes;
  }

  if (file) {
    payload.file = file;
  }

  if (formField?.id) {
    payload.imageId = formField?.id;
  } else {
    const [sectionName, subSectionName] = name.split('.');
    payload.sectionName = sectionName;
    payload.subSectionName = subSectionName;
  }

  console.log(label, 'label in createUpsertImagePayload');

  if (label) {
    payload.label = label;
  } else if (formField?.label) {
    payload.label = formField.label;
  }

  return payload;
};
